import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import server from "../Config";
import { useDispatch } from 'react-redux';
import { clean_image, change_image } from '../redux/Appslice';
import { useSelector } from "react-redux";


const Deleteimg = ({id, type, api}) => {

const token = sessionStorage.getItem("token");
const lang = useSelector((state) => state.appState.langs[0]);

const dispatch = useDispatch();

const delImg = (id, type) => {
    confirmAlert({
      title: 'Confirm to Delete image',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => trashImg(id, type)
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const trashImg = (id, type) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const formdata = new FormData();
    
    if(api === "taxonomy"){
        var url_api = "/api/taxonomy/";
    }else if(api === "post"){
        var url_api = "/api/post/";
    }
    
    formdata.append('type', type);
    formdata.append('lang', lang);

    const requestOptions = {
    method:'PUT',
    headers: myHeaders, 
    body:formdata,
    redirect: 'follow'
    };

    fetch(server + url_api + id, requestOptions)
    .then(response => response.json())
    .then(
      dispatch(clean_image()),
      dispatch(change_image(true))
    )
    .catch(error => console.log('error', error));
   }


    return(
        <div className="delete_img" onClick={() => delImg(id, type)}>Delete image</div>
    );
}

export default Deleteimg;