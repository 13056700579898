import React, { Fragment } from "react";
import Checklogin from "../middleware/Checklogin";
import { useParams } from "react-router-dom";
import Updatelang from "../components/Updatelang";
import Addlang from "../components/Addlang";


const Language = () => {
    const { id } = useParams();
    return(
    <Fragment>
        <Checklogin />
        { id ? <Updatelang />
             : <Addlang />
        }
   </Fragment>
   );
 
}

export default Language;