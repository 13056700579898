import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import server from "../Config";

const Login = () => {

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState("");
    const [loginin, setLoginin] = useState(undefined);
    const [loginfinal, setLoginfinal] = useState(false);

    const userChange = (e) => {
        setUser(e.target.value);
    }
    const passChange = (e) => {
        setPass(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "");
        myHeaders.append("Content-Type", "application/json");
        
        const raw = JSON.stringify({"name":user,"password":pass});
        
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch(server+"/api/user/login", requestOptions)
          .then(response => response.json())
          .then(result => {
                    setError(result[0].message);
                    sessionStorage.setItem("id", result[0].id);
                    sessionStorage.setItem("user", result[0].user);
                    sessionStorage.setItem("role", result[0].role);
                    sessionStorage.setItem("token", result[0].token);
                    setLoginin(sessionStorage.getItem("token"));
          })
          .catch(error => console.log('error', error));

         
        
    }

    useEffect(() => {
        if(loginin === "undefined"){
            setLoginfinal(false);
        } else if(!loginin){
            setLoginfinal(false);
        } else {
            setLoginfinal(true);
        }
    },[loginin]);


    return(
        loginfinal === false ? 
        <div id="login">
            <div className="logo_login"><img src="img/logo_tilventis.png" alt="logo"></img></div>
            <div id="login_form">
            <form id="form_login" onSubmit={handleSubmit}>
                <div className="input_fields">
                    <label>User</label>
                    <input id="usuario" type="text" value={user} onChange={userChange} />
                    <div className="clear"></div>
                    <label>Password</label>
                    <input id="password" type="password" value={pass} onChange={passChange} />
                </div>
                <div className="clear"></div>
                <input className="bn_success" type="submit" value="Sign In" />
                <div className="recover"><Link to="/recover">Recover Account</Link></div>
                <div className="clear"></div>
                <div className="error">{error}</div>
            </form>
            </div>
        </div>
        :
        <Navigate to="/posts" />
    );
}

export default Login;