import React, { Fragment } from "react";
import Adduser from "../components/Adduser";
import Updateuser from "../components/UpdateUser";
import Checklogin from "../middleware/Checklogin";
import { useParams } from "react-router-dom";


const User = () => {
    const { id } = useParams();
    return(
    <Fragment>
        <Checklogin />
        { id ? <Updateuser /> 
             : <Adduser />
        }
   </Fragment>
   );
 
}

export default User;