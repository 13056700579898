import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
	name: 'appState',
	initialState: {
		langs: ["es"],
		images: [false],
		updatetaxonomy:[0],
		cty:[],
		op:[],
		category:[],
		tag:[],
		provider:[],
		product:[],
		delete:[0]
	},
	reducers: {
		change_lang: (state, action) => {
			state.langs.push(action.payload);
		},
		clean_lang: (state, action) => {
			state.langs.splice(0);
		},
		change_image: (state, action) => {
			state.images.push(action.payload);
		},
		clean_image: (state, action) => {
			state.images.splice(0);
		},
		change_taxonomy: (state, action) => {
			state.updatetaxonomy.push(action.payload);
		},
		clean_taxonomy: (state, action) => {
			state.updatetaxonomy.splice(0);
		},
		change_cty:(state, action) => {
			state.cty.push(action.payload);
		},
		clean_cty: (state, action) => {
			state.cty.splice(0);
		},
		change_op:(state, action) => {
			state.op.push(action.payload);
		},
		clean_op: (state, action) => {
			state.op.splice(0);
		},
		change_tag:(state, action) => {
			state.tag.push(action.payload);
		},
		clean_tag: (state, action) => {
			state.tag.splice(0);
		},
		change_category:(state, action) => {
			state.category.push(action.payload);
		},
		clean_category: (state, action) => {
			state.category.splice(0);
		},
		change_provider:(state, action) => {
			state.provider.push(action.payload);
		},
		clean_provider: (state, action) => {
			state.provider.splice(0);
		},
		change_product:(state, action) => {
			state.product.push(action.payload);
		},
		clean_product: (state, action) => {
			state.product.splice(0);
		},
		change_delete:(state, action) => {
			state.delete.push(action.payload);
		},
		clean_delete: (state, action) => {
			state.delete.splice(0);
		}
	}
});


export const { change_lang, clean_lang, change_image, clean_image, clean_taxonomy, change_taxonomy, change_cty, clean_cty, change_op, clean_op, change_tag, clean_tag, change_category, clean_category, change_provider, clean_provider, change_product, clean_product, change_delete, clean_delete } = appSlice.actions;
export default appSlice.reducer;
