import React, { Fragment } from "react";
import Addtaxonomy from "../components/Addtaxonomy";
import Taxonomylist from "../components/Taxonomylist";
import Checklogin from "../middleware/Checklogin";

const Taxonomies = () => {  

    return(
        <Fragment>
        <Checklogin />
        <div className="container">
            <div className="left_content_taxonomy">
                <Addtaxonomy />
            </div>
            <div className="right_content_taxonomy">
                <Taxonomylist />
            </div>
        </div>
        </Fragment>
    );
}

export default Taxonomies;