import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Deleteimg from "./Deleteimg";
import Languagecontent from "./Languagecontent";
import server from "../Config";
import { useSelector } from "react-redux";


const Updatetaxonomy = () => {
    const { id, tax } = useParams();

    const imgdelete = useSelector((state) => state.appState.images[0]);
    const lang = useSelector((state) => state.appState.langs[0]);
    const [colorinstance, setColorinstance] = useState("red"); 
    const [refresh, setRefresh] = useState(0);
    const [message, setMessage] = useState("");
    const [iconhover, setIconhover] = useState("");
    const [icon, setIcon] = useState("");
    const [background, setBackground] = useState("");
    const [img, setImg] = useState("");
    const [color, setColor] = useState("");
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const [slug, setSlug] = useState("");
    const [data, setData] = useState([{}]);
    const token = sessionStorage.getItem("token");

    useEffect(() => {
            
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");
        
            const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
        
           
            fetch(server+"/api/taxonomy/"+id+"?lang="+lang, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result);
                setName(result[0].name);
                setColor(result[0].color);
                setText(result[0].text);
                setIconhover(result[0].icon_hover);
                setIcon(result[0].icon);
                setBackground(result[0].background);
                setImg(result[0].img);
                setSlug(result[0].slug);
            })
            .catch(error => console.log('error', error));
        
            },[refresh, lang, imgdelete]);

    

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const taxonomy = document.getElementById("taxonomy").value;

            if(name){

                if(img === undefined){
                    var valImg = "";
                }else{
                    valImg = img;
                }

                if(background === undefined){
                    var valBackground = "";
                }else{
                    valBackground = background;
                }

                if(icon === undefined){
                    var valIcon = "";
                }else{
                    valIcon = icon;
                }

                if(iconhover === undefined){
                    var valIconhover = "";
                }else{
                    valIconhover = iconhover;
                }
               
                const myHeaders = new Headers();
                myHeaders.append("Authorization", token);

                const formdata = new FormData();

                formdata.append('name', name);
                formdata.append('text', text);
                formdata.append('color', color);
                formdata.append('slug', slug);
                formdata.append('taxonomy', taxonomy);              
                formdata.append('img', valImg);
                formdata.append('background', valBackground);
                formdata.append('icon', valIcon);
                formdata.append('icon_hover', valIconhover);
                formdata.append('lang', lang);
        
                const requestOptions = {
                method:'PUT',
                headers: myHeaders, 
                body:formdata,
                redirect: 'follow'
                };
          
                fetch(server+"/api/taxonomy/" + id, requestOptions)
                .then(response => response.json())
                .then(result => {
                  if(result[0].message){
                        setRefresh(refresh+1);
                        setColorinstance("forestGreen");
                        setMessage("Your content was successfully updated");
                        setTimeout(()=>{
                            setMessage("");
                        }, 5000);
                    }
                   
                })
                .catch(error => console.log('error', error));
            } else {
                    setColorinstance("red");
                    setMessage("Please enter a name");
                setTimeout(()=>{
                    setMessage("");
                }, 3000);
            }
            
    }

    return(
            <form onSubmit={handleSubmit} className="addtaxonomy">
                { data.map((taxonomy, index) => (
                    <Fragment key={index}>
                <button className="btn btn_primary" type="submit">Update {tax}</button>
                <div id="message_post" style={{color:colorinstance}}>{message}</div>
                <Languagecontent />
                <div className="input_group">
                    <span className="input_group_text">Name</span>
                    <input name="name" id="name" onChange={(e) => setName(e.target.value)} type="text" value={name} />
                </div>
                <div className="input_group">
                    <span className="input_group_text">Text</span>
                    <input name="text" id="text" onChange={(e) => setText(e.target.value)} type="text" value={text} />
                </div>
                 <div className="input_group">
                    <span className="input_group_text">slug</span>
                    <input className="readonly" name="slug" type="text" value={slug} readOnly />
                </div> 
               
                 <input name="taxonomy" id="taxonomy" type="hidden" value={tax} />
                 <label>Main image</label>
                 <input name="img" id="img" type="file" onChange={(e) => setImg(e.target.files[0])} accept="image/*" />
                 { taxonomy.img ?
                <div className="img_preview">
                    <img src={`${taxonomy.img}?${global.Date.now()}`} alt="img preview"></img>
                    <Deleteimg id={taxonomy.id} type="img" api="taxonomy" />
                </div>
                 : null }
                 <label>Background</label>
                 <input  name="background" id="background" type="file"  onChange={(e) => setBackground(e.target.files[0])} accept="image/*" />
                 { taxonomy.background ?
                <div className="img_preview">
                    <img src={`${taxonomy.background}?${global.Date.now()}`} alt="img preview"></img>
                    <Deleteimg id={taxonomy.id} type="background" api="taxonomy" />
                </div>
                 : null }
                 <label>Icon</label>
                 <input  name="icon" id="icon" type="file" onChange={(e) => setIcon(e.target.files[0])} accept="image/*" />
                 { taxonomy.icon ?
                <div className="img_preview">
                    <img src={`${taxonomy.icon}?${global.Date.now()}`} alt="img preview"></img>
                    <Deleteimg id={taxonomy.id} type="icon" api="taxonomy" />
                </div>
                 : null }
                 <label>Icon hover</label>
                 <input  name="icon_hover" id="icon_hover" type="file"  onChange={(e) => setIconhover(e.target.files[0])} accept="image/*" />
                 { taxonomy.icon_hover ?
                <div className="img_preview">
                    <img src={`${taxonomy.icon_hover}?${global.Date.now()}`} alt="img preview"></img>
                    <Deleteimg id={taxonomy.id} type="icon_hover" api="taxonomy" />
                </div>
                 : null }
                 <label>Color</label>
                 <div className="color_div">
                     <div className="container_color">
                         <div className="show_color" style={{backgroundColor:color}}></div>
                     </div>
                    <input style={{opacity:0}} className="color" name="color" id="color" onChange={(e) => setColor(e.target.value)} type="color" defaultValue={color}></input>
                 </div>
                 </Fragment>
                 ))
                }
            </form>
            
    );
}

export default Updatetaxonomy;