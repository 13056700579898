import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faBarsStaggered } from '@fortawesome/free-solid-svg-icons'
import Logout from "./Logout";
const Head = () => {

    const hamburgerHandle = () => {
        document.getElementById("root").classList.add("leave");
        document.getElementById("root").classList.remove("enter");
    }

    const arrowHandle = ( ) => {
        document.getElementById("root").classList.add("enter");
        document.getElementById("root").classList.remove("leave");
      
    }

    return(
        <Fragment>
        <div id="content_top">
        <div id="header">
            <div id="menu_icon">
                <div id="hamburger" onClick={hamburgerHandle}><FontAwesomeIcon  icon={faBarsStaggered} /></div>
                <div id="arrow" onClick={arrowHandle}><FontAwesomeIcon  icon={faArrowRightLong} /></div>
            </div>
            <Logout />
        </div>
        <div className="page_title">CONTENT</div>
        </div>
        </Fragment>
    );
}

export default Head;