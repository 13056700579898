import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Checklogin from "../middleware/Checklogin";
import Delete from "../components/Delete";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import server from "../Config";
import { useSelector } from "react-redux";

const Users = () => {

    const [data, setData] = useState([{}]);
    const role = sessionStorage.getItem("role");
    const [del, setDel] = useState("");
    const trash = useSelector((state) => state.appState.delete[0]);


     const submit = (id) => {
      confirmAlert({
        title: 'Confirm to Delete id:'+id,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { 
              setDel(id);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
    
    var con = 0;

    useEffect(() => {
      con++
      if(con === 1){
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        
        fetch(server+"/api/user", requestOptions)
        .then(response => response.json())
        .then(result => {
          result.message ?
            setData([{
                "id":"",
                "name":"",
                "email":"",
                "role":""
            }])
            :
            setData(result);
        })
        .catch(error => console.log('error', error));
      }
    },[trash]);

    return(
      <Fragment>
        <Checklogin />
  
     { role === "admin" ?
      <Fragment>
        <div className="container">
        <button className="btn btn_primary" id="new_content" type="button"><Link to="/user">New User</Link></button>
            <table>
            <thead>
                <tr className="head_tr">
                    <td>ID</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Role</td>
                </tr>
            </thead>
            <tbody>
               
                { 
                data.map((user, index)  => (
                <Fragment key={index}>
                <tr>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                        <div className="pencil"><Link to={`/user/${user.id}`}><FontAwesomeIcon  icon={faPencil} /></Link></div>
                        <div className="trash" onClick={() => submit(user.id)}><FontAwesomeIcon  icon={faTrashCan} /></div>
                    </td>
                </tr>
                </Fragment> 
                     ))
                }
                
            </tbody>
            </table>
        </div>
        <Delete type="user" id={del} />
        </Fragment>
       :
       null 
      }

      </Fragment>
      
    );
}

export default Users;