import { useEffect } from "react";
import server from "../Config";
import { useDispatch } from 'react-redux';
import { change_delete, clean_delete } from '../redux/Appslice';

const Delete = ({id, type}) => {

  const dispatch = useDispatch();

  useEffect(() => {
  if(id > 0){
    const token = sessionStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
    };

    fetch(server+"/api/"+ type + "/"+id, requestOptions)
    .then(response => {
      response.json();
      dispatch(clean_delete());
      dispatch(change_delete(id));
    })
    .catch(error => console.log('error', error));
    }
},[id]);
       
}

export default Delete;