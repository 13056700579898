import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Checklogin from "../middleware/Checklogin";
import server from "../Config";
import Deleteimg from "./Deleteimg";
import Cty from "./taxonomies/Cty";
import Op from "./taxonomies/Op";
import Category from "./taxonomies/Category";
import Tag from "./taxonomies/Tag";
import Provider from "./taxonomies/Provider";
import Product from "./taxonomies/Product";
import Languagecontent from "./Languagecontent";
import { useSelector } from "react-redux";
import { clean_cty, clean_op, clean_tag, clean_provider, clean_category } from '../redux/Appslice';
import { useDispatch } from 'react-redux';



const Updatepost = () => {

 const lang = useSelector((state) => state.appState.langs[0]);
 const [taxonomies, setTaxonomies] = useState([{}]);  
 const [colorinstance, setColorinstance] = useState("red"); 
 const [message, setMessage] = useState("");
 const [title, setTitle] = useState("");
 const [author, setAuthor] = useState("");
 const [slug, setSlug] = useState("");
 const [text, setText] = useState("");
 const [video, setVideo] = useState("");
 const [cmsid, setCmsid] = useState("");
 const [videopreview, setVideopreview] = useState("");
 const [mainimg, setMainimg] = useState("");
 const [secondaryimg, setSecondaryimg] = useState("");
 const [desktopcontent, setDesktopcontent] = useState(false);
 const [date, setDate] = useState("");
 const [data, setData] = useState([{}]);   
 const [category, setCategory] = useState();
 const [tag, setTag] = useState();
 const [cty, setCty] = useState();
 const [op, setOp] = useState();
 const [provider, setProvider] = useState();
 const [product, setProduct] = useState();

 const { id } = useParams();
 const dispatch = useDispatch();

 const token = sessionStorage.getItem("token");

const cty_input = useSelector((state) => state.appState.cty[0]);
const op_input = useSelector((state) => state.appState.op[0]);
const tag_input = useSelector((state) => state.appState.tag[0]);
const category_input = useSelector((state) => state.appState.category[0]);
const provider_input = useSelector((state) => state.appState.provider[0]);
const product_input = useSelector((state) => state.appState.product[0]);

useEffect(() => {

        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        fetch(server+"/api/post/"+id+"?lang="+lang, requestOptions)
        .then(response => response.json())
        .then(result => {
            setData(result);
            setTitle(result[0].title);
            setAuthor(result[0].author);
            setSlug(result[0].slug);
            setText(result[0].text);
            setVideo(result[0].video);
            setCmsid(result[0].cms_id);
            setVideopreview(result[0].video_preview);
            setMainimg(result[0].main_img);
            setSecondaryimg(result[0].secondary_img);
            setDesktopcontent(result[0].desktop_content);
            setDate(result[0].date);
            setTaxonomies(result[0].taxonomies);
        })
        .catch(error => console.log('error', error));
},[id, lang]);

if(desktopcontent === "true" || desktopcontent === true){
    var desktop = true;
} else if(desktopcontent === "false" || desktopcontent === false) {
        desktop = false;
}


const handleSubmit = (e) => {
    e.preventDefault();

    if(title){

    const taxonomie_default = [];

 if(cty_input){
    cty_input.map(cty => {
        taxonomie_default.push(cty.value);
     }); 
 }

 if(op_input){
    op_input.map(op => {
        taxonomie_default.push(op.value);
     }); 
 }
if(tag_input){
    tag_input.map(tag => {
        taxonomie_default.push(tag.value);
     }); 
}
if(category_input){
    category_input.map(category => {
        taxonomie_default.push(category.value);
     }); 
}
if(provider_input){
    provider_input.map(provider => {
        taxonomie_default.push(provider.value);
     }); 
}
if(product_input){
    product_input.map(product => {
        taxonomie_default.push(product.value);
     }); 
}
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        const formdata = new FormData();
       
        formdata.append('title', title);
        formdata.append('slug', slug);
        formdata.append('text', text);
        formdata.append('author', author);
        formdata.append('video', video);
        formdata.append('cms_id', cmsid);
        formdata.append('video_preview', videopreview);              
        formdata.append('main_img', mainimg);
        formdata.append('secondary_img', secondaryimg);
        formdata.append('date', date);
        formdata.append('desktop_content', desktopcontent);
        formdata.append('taxonomies', taxonomie_default);    
       

        const requestOptions = {
        method:'PUT',
        headers: myHeaders, 
        body:formdata,
        redirect: 'follow'
        };

        fetch(server+"/api/post/"+id+"?lang="+lang, requestOptions)
        .then(response => response.json())
        .then(() => {
            
            setMessage("Your content was successfully Updated");
            setColorinstance("forestGreen");
            setTimeout(()=>{
                setMessage("");
            }, 5000);
        })
        .catch(error => console.log('error', error));
    } else {
        setColorinstance("red");
        setMessage("Please enter a title");
        setTimeout(()=>{
            setMessage("");
        }, 3000);
    }
    
}

useEffect(() => {
    

    const category_arr = [];
    const tag_arr = [];
    const cty_arr = [];
    const op_arr = [];
    const provider_arr = [];
    const product_arr = [];

    setCategory(category_arr);
    setTag(tag_arr);
    setCty(cty_arr);
    setOp(op_arr);
    setProvider(provider_arr);
    setProduct(product_arr);

if(taxonomies){
taxonomies.map(data => {
    if(data.taxonomy === "category"){
        category_arr.push({ value: data.id, label: data.name });
    } else if(data.taxonomy === "tag"){
        tag_arr.push({ value: data.id, label: data.name });
    } else if(data.taxonomy === "cty"){
        cty_arr.push({ value: data.id, label: data.name });
    } else if(data.taxonomy === "op"){
        op_arr.push({ value: data.id, label: data.name });
    } else if(data.taxonomy === "provider"){
        provider_arr.push({ value: data.id, label: data.name });
    } else if(data.taxonomy === "product"){
        product_arr.push({ value: data.id, label: data.name });
    }
});
}
},[taxonomies]);

    return(
        <Fragment>
        <Checklogin />
        <div className="container">
        <button className="btn btn_primary" id="new_content" type="button"><Link to="/post"  onClick={()=> { 
          dispatch(clean_cty());
          dispatch(clean_op());
          dispatch(clean_tag());
          dispatch(clean_category());
          dispatch(clean_provider());

          }}>New content</Link></button>
            <form onSubmit={handleSubmit}>
                { data.map((data, index) => (
                    <Fragment key={index}>
            <div className="content_left">
            <Languagecontent />
            <div className="input_group">
                <span className="input_group_text">Slug / Auto generated</span>
                    <input className="readonly" name="slug" id="slug" type="text" value={slug} readOnly />
            </div>
            <div className="input_group">
                <span className="input_group_text">Author</span>
                    <input className="readonly" name="author" type="text" value={author} readOnly />
            </div>
            <div className="input_group">
                <span className="input_group_text">Title</span>
                    <input name="title"  type="text" onChange={(e) => setTitle(e.target.value)} value={title} />
            </div>
            <div className="input_group">
                <span className="input_group_text">Video</span>
                <input name="video" onChange={(e) => setVideo(e.target.value)} type="text" value={video}/>
            </div>
            <div className="input_group">
                <span className="input_group_text">Cms Id</span>
                <input name="cms_id" onChange={(e) => setCmsid(e.target.value)} type="text" value={cmsid}/>
            </div>
            <div className="input_group">
                 <span className="input_group_text">Text</span>
                 <textarea name="text" onChange={(e) => setText(e.target.value)} rows="2" value={text}/>
            </div>
            </div>
            <div className="content_right">
                <button className="btn btn_primary" type="submit">Update</button>
                <div id="message_post" style={{color:colorinstance}}>{message}</div>
                <label>Desktop content</label>
                <label className="switch">
                <input type="checkbox" name="desktop_content" checked={desktop} onChange={() => setDesktopcontent(!desktop)} />
                     <span className="slider"></span>
                </label>
                <label>Date</label>
                <input type="date" name="date" onChange={(e) => setDate(e.target.value)} value={date} />
                <div className="select_multiple">
                <label>Products</label>
                   <Product defaultValue={product} />
                </div>
                <div className="select_multiple">
                <label>Categories</label>
                    <Category defaultValue={category} />
                </div>
                <div className="select_multiple">
                <label>Tags</label>
                    <Tag defaultValue={tag} />
                </div>
                <div className="select_multiple">
                <label>Countries</label>
                    <Cty defaultValue={cty} />
                </div>
                <div className="select_multiple">
                <label>Operators</label>
                    <Op defaultValue={op} />
                </div>
                <div className="select_multiple">
                <label>Providers</label>
                    <Provider defaultValue={provider} />
                </div>
                <label>Video preview</label>
                <input  name="videopreview" type="file" onChange={(e) => setVideopreview(e.target.files[0])}/>
                { data.video_preview ?
                <div className="img_preview">
                    <img src={data.video_preview} alt="img preview"></img>
                    <Deleteimg id={data.id} type="video_preview" api="post" />
                </div>
                 : null }
                <label>Main image</label>
                <input name="mainimg" type="file" onChange={(e) => setMainimg(e.target.files[0])} />
                { data.main_img ?
                <div className="img_preview">
                    <img src={data.main_img} alt="img preview"></img>
                    <Deleteimg id={data.id} type="main_img" api="post" />
                </div>
                 : null }
                <label>Secondary image</label>
                <input name="secondaryimg" type="file" onChange={(e) => setSecondaryimg(e.target.files[0])} />
                { data.secondary_img ?
                <div className="img_preview">
                    <img src={data.secondary_img} alt="img preview"></img>
                    <Deleteimg id={data.id} type="secondary_img" api="post" />
                </div>
                 : null }
            </div>
            </Fragment>
            ))}
              
            </form>
        </div>
        </Fragment>
    );   
}

export default Updatepost;