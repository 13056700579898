import React, { useEffect, useState }from "react";
import { useDispatch } from 'react-redux';
import { change_lang, clean_lang } from '../redux/Appslice';
import server from "../Config";

const Languagecontent = () => {

    const dispatch = useDispatch();
    const [langs, setLangs] = useState([{}]);

    useEffect(() => {
      
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        fetch(server+"/api/lang", requestOptions)
        .then(response => response.json())
        .then((result) => {
            setLangs(result);
        })
        .catch(error => console.log('error', error));

    },[]);

    return(
        <div id="langs">
            <label htmlFor="langs">Language Content</label>
            <select
                id="lang"
                name="lang"
                onChange={e => {
                    dispatch(clean_lang());
                    dispatch(change_lang(e.target.value));
                }}>
                    {
                        langs.map((langs, index) => (
                            <option key={index} value={langs.slug}>{langs.name}</option>
                        ))
                }
            </select>
        </div>
    );
}

export default Languagecontent;