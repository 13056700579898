import React, { useState } from "react";
import { useParams } from "react-router-dom";
import server from "../Config";
import { change_taxonomy, clean_taxonomy } from '../redux/Appslice';
import { useDispatch } from 'react-redux';

const Addtaxonomy = () => {
    const { tax } = useParams();
    const dispatch = useDispatch();

    const [colorinstance, setColorinstance] = useState("red"); 
    const [message, setMessage] = useState("");
    const [iconhover, setIconhover] = useState("");
    const [icon, setIcon] = useState("");
    const [background, setBackground] = useState("");
    const [img, setImg] = useState("");
    const [color, setColor] = useState("");
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const token = sessionStorage.getItem("token");


    if(name !== ""){
        const nameLowercase = name.toLowerCase();
        document.getElementById("slug").value = nameLowercase.replace(/\s/g,"-");
     }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const slug = document.getElementById("slug").value;
        const taxonomy = document.getElementById("taxonomy").value;
     
            if(name){

                if(img === undefined){
                    var valImg = "";
                }else{
                    valImg = img;
                }

                if(background === undefined){
                    var valBackground = "";
                }else{
                    valBackground = background;
                }

                if(icon === undefined){
                    var valIcon = "";
                }else{
                    valIcon = icon;
                }

                if(iconhover === undefined){
                    var valIconhover = "";
                }else{
                    valIconhover = iconhover;
                }
        
                const myHeaders = new Headers();
                myHeaders.append("Authorization", token);

                const formdata = new FormData();

       
                formdata.append('name', name);
                formdata.append('text', text);
                formdata.append('color', color);
                formdata.append('slug', slug);
                formdata.append('taxonomy', taxonomy);              
                formdata.append('img', valImg);
                formdata.append('background', valBackground);
                formdata.append('icon', valIcon);
                formdata.append('icon_hover', valIconhover);
               
        
                const requestOptions = {
                method:'POST',
                headers: myHeaders, 
                body:formdata,
                redirect: 'follow'
                };
          
                fetch(server+"/api/taxonomy/", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result[0].insertId){
                        dispatch(clean_taxonomy());
                        dispatch(change_taxonomy(result[0].insertId));
                        setColorinstance("forestGreen");
                        setMessage("Your content was successfully created");
                        setTimeout(()=>{
                            setMessage("");
                        }, 5000);
                    }
                   
                })
                .catch(error => console.log('error', error));
            } else {
                    setColorinstance("red");
                    setMessage("Please enter a name");
                setTimeout(()=>{
                    setMessage("");
                }, 3000);
            }
            
    }

    return(
            <form onSubmit={handleSubmit} className="addtaxonomy">
                <button className="btn btn_primary" type="submit">Add new {tax}</button>
                <div id="message_post" style={{color:colorinstance}}>{message}</div>
                <div className="input_group">
                    <span className="input_group_text">Name</span>
                    <input name="name" id="name" onChange={(e) => setName(e.target.value)} type="text" required />
                </div>
                <div className="input_group">
                    <span className="input_group_text">Text</span>
                    <input name="text" id="text" onChange={(e) => setText(e.target.value)} type="text" />
                </div>
                <div className="input_group">
                    <span className="input_group_text">slug</span>
                    <input id="slug" className="readonly" name="slug" type="text" readOnly />
                </div>
                 <input name="taxonomy" id="taxonomy" type="hidden" value={tax} />
                 <label>Main image</label>
                 <input name="img" id="img" type="file" onChange={(e) => setImg(e.target.files[0])} accept="image/*" />
                 <label>Background</label>
                 <input  name="background" id="background" type="file"  onChange={(e) => setBackground(e.target.files[0])} accept="image/*" />
                 <label>Icon</label>
                 <input  name="icon" id="icon" type="file" onChange={(e) => setIcon(e.target.files[0])} accept="image/*" />
                 <label>Icon hover</label>
                 <input  name="icon_hover" id="icon_hover" type="file"  onChange={(e) => setIconhover(e.target.files[0])} accept="image/*" />
                 <label>Color</label>
                 <input className="color" name="color" id="color" onChange={(e) => setColor(e.target.value)} type="color" ></input>
                 
             
            </form>
            
    );
}

export default Addtaxonomy;