import React, { Fragment, useEffect, useState } from "react";
import Head from "./Head"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright, faEarthAmericas, faGlobe, faListCheck, faPenClip, faTag, faUsers, faRectangleList, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from "react-router-dom";
import server from "../Config";

const Menu = () => {

    const location = useLocation();

    const [data, setData] = useState();
    const [role, setRol] = useState("editor");
    

    useEffect(() => {

        const token = sessionStorage.getItem("token");
        const rolStorage = sessionStorage.getItem("role");
        setRol(rolStorage);
    
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        fetch(server+"/api/user/validate", requestOptions)
        .then(response => response.json())
        .then(result => {
            setData(result[0].validate);
        })
        .catch(error => console.log('error', error));
    },[location]);
   

    return(
            data === 1 ?
            <Fragment>
        <div id="navbar_menu">
            <img className="logo_menu" src="/img/logo_tilventis.png" alt="logo"></img>
            <h2>CONTENT</h2>
            <div className="menu_item"><Link to="posts"><span className="icon_menu"><FontAwesomeIcon  icon={faPenClip} /></span><span className="text_menu">Entries</span></Link></div>
            <h2>TAXONOMIES</h2>
            <div className="menu_item"><Link to="taxonomy/product"><span className="icon_menu"><FontAwesomeIcon  icon={faRectangleList} /></span><span className="text_menu">Products</span></Link></div>
            <div className="menu_item"><Link to="taxonomy/category"><span className="icon_menu"><FontAwesomeIcon  icon={faListCheck} /></span><span className="text_menu">Categories</span></Link></div>
            <div className="menu_item"><Link to="taxonomy/tag"><span className="icon_menu"><FontAwesomeIcon  icon={faTag} /></span><span className="text_menu">Tags</span></Link></div>
            <div className="menu_item"><Link to="taxonomy/cty"><span className="icon_menu"><FontAwesomeIcon  icon={faEarthAmericas} /></span><span className="text_menu">Countrys</span></Link></div>
            <div className="menu_item"><Link to="taxonomy/op"><span className="icon_menu"><FontAwesomeIcon  icon={faGlobe} /></span><span className="text_menu">Operators</span></Link></div>
            <div className="menu_item"><Link to="taxonomy/provider"><span className="icon_menu"><FontAwesomeIcon  icon={faCopyright} /></span><span className="text_menu">Providers</span></Link></div>
            <h2>LANGUAGES</h2>
            <div className="menu_item"><Link to="languages"><span className="icon_menu"><FontAwesomeIcon  icon={faLanguage} /></span><span className="text_menu">Languages</span></Link></div>
            { role === "admin" ?
            <Fragment>
            <h2>USERS</h2>
            <div className="menu_item"><Link to="users"><span className="icon_menu"><FontAwesomeIcon  icon={faUsers} /></span><span className="text_menu">Users</span></Link></div>
            </Fragment>
            : null
            }
        </div>
        <Head />
        </Fragment>
        :
        null
    );
}

export default Menu;