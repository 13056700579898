import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

const Logout = () => {
    return(
        <div id="logout" onClick={() => { 
            sessionStorage.clear();
            window.location.reload();
        }}><FontAwesomeIcon  icon={faSignOut} /></div>
    );
}

export default Logout;