import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Posts from "./pages/Posts";
import Menu from "./components/Menu";
import Post from "./pages/Post";
import Taxonomies from "./pages/Taxonomies";
import Taxonomy from "./pages/Taxonomy";
import Users from "./pages/Users";
import User from "./pages/User";
import Languages from "./pages/Languages";
import Language from "./pages/Language";

const App = () => {
  return(
    <BrowserRouter>
        <Menu />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/post" element={<Post />} />
          <Route path="/taxonomy/:tax" element={<Taxonomies />} />
          <Route path="/taxonomy/:tax/:id" element={<Taxonomy />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user" element={<User />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/languages/" element={<Languages />} />
          <Route path="/language/" element={<Language />} />
          <Route path="/language/:id" element={<Language />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
