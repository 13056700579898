import React, { Fragment, useState } from "react";
import Checklogin from "../middleware/Checklogin";
import server from "../Config";
import { Navigate } from "react-router-dom";
import Cty from "./taxonomies/Cty";
import Op from "./taxonomies/Op";
import Category from "./taxonomies/Category";
import Tag from "./taxonomies/Tag";
import Provider from "./taxonomies/Provider";
import Product from "./taxonomies/Product";
import { useSelector } from "react-redux";




const Addpost = () => {

 const newdate = new Date();
 const current_date = newdate.getFullYear()+"-"+(newdate.getMonth()+1)+"-"+ newdate.getDate();

 const [colorinstance, setColorinstance] = useState("red"); 
 const [message, setMessage] = useState("");
 const [title, setTitle] = useState("");
 const [text, setText] = useState("");
 const [video, setVideo] = useState("");
 const [cmsid, setCmsid] = useState("");
 const [videopreview, setVideopreview] = useState("");
 const [mainimg, setMainimg] = useState("");
 const [secondaryimg, setSecondaryimg] = useState("");
 const [desktopcontent, setDesktopcontent] = useState(false);
 const [date, setDate] = useState(current_date);
 const [redirect, setRedirect] = useState(false);
 const [id, setId] = useState(-1);



const cty = useSelector((state) => state.appState.cty[0]);
const op = useSelector((state) => state.appState.op[0]);
const tag = useSelector((state) => state.appState.tag[0]);
const category = useSelector((state) => state.appState.category[0]);
const provider = useSelector((state) => state.appState.provider[0]);
const product = useSelector((state) => state.appState.product[0]);

const token = sessionStorage.getItem("token");
const user = sessionStorage.getItem("user");


if(title !== ""){
   const titleLowercase = title.toLowerCase();
   document.getElementById("slug").value = titleLowercase.replace(/\s/g,"-");

}

const handleSubmit = (e) => {
    e.preventDefault();

    const slug = document.getElementById("slug").value;

if(title){

const taxonomies = [];

 if(cty){
    cty.map(cty => {
        taxonomies.push(cty.value);
     }); 
 }

 if(op){
    op.map(op => {
        taxonomies.push(op.value);
     }); 
 }
if(tag){
    tag.map(tag => {
        taxonomies.push(tag.value);
     }); 
}
if(category){
    category.map(category => {
        taxonomies.push(category.value);
     }); 
}
if(provider){
    provider.map(provider => {
        taxonomies.push(provider.value);
     }); 
}
if(product){
    product.map(product => {
        taxonomies.push(product.value);
     }); 
}

        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        const formdata = new FormData();
       
                formdata.append('title', title);
                formdata.append('slug', slug);
                formdata.append('text', text);
                formdata.append('author', user);
                formdata.append('video', video);
                formdata.append('cms_id', cmsid);
                formdata.append('video_preview', videopreview);              
                formdata.append('main_img', mainimg);
                formdata.append('secondary_img', secondaryimg);
                formdata.append('date', date);
                formdata.append('desktop_content', desktopcontent);
                formdata.append('taxonomies', taxonomies);               
        
                const requestOptions = {
                method:'POST',
                headers: myHeaders, 
                body:formdata,
                redirect: 'follow'
                };
  

        fetch(server+"/api/post/", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result[0].insertId){
                setId(result[0].insertId);
                setTimeout(()=>{
                    setRedirect(true);
                }, 500);
            }else{
                setMessage("Your content was not created");
                setColorinstance("red");
                setTimeout(()=>{
                    setMessage("");
                }, 5000);
            }
          
        })
        .catch(error => console.log('error', error));
    } else {
        setColorinstance("red");
        setMessage("Please enter a title");
        setTimeout(()=>{
            setMessage("");
        }, 3000);
    }
  
}

    return(
        <Fragment>
        <Checklogin />
        <div className="container">

            <form onSubmit={handleSubmit}>
             
            <div className="content_left">
            <div className="input_group">
                <span className="input_group_text">Slug / Auto generated</span>
                    <input className="readonly" name="slug" id="slug" type="text" readOnly />
            </div>
            <div className="input_group">
                <span className="input_group_text">Author</span>
                    <input className="readonly" name="author" type="text" value={user}  readOnly />
            </div>
            <div className="input_group">
                <span className="input_group_text">Title</span>
                    <input name="title" onChange={(e) => setTitle(e.target.value)} type="text" required />
            </div>
            <div className="input_group">
                <span className="input_group_text">Video</span>
                    <input name="video" onChange={(e) => setVideo(e.target.value)} type="text" />
            </div>
            <div className="input_group">
                <span className="input_group_text">Cms Id</span>
                    <input name="cms_id" onChange={(e) => setCmsid(e.target.value)} type="text" />
            </div>
            <div className="input_group">
                 <span className="input_group_text">Text</span>
                 <textarea name="text" onChange={(e) => setText(e.target.value)} rows="2" />
            </div>
            </div>
            <div className="content_right">
                <button className="btn btn_primary" type="submit">Save</button>
                <div id="message_post" style={{color:colorinstance}}>{message}</div>
                <label>Desktop content</label>
                <label className="switch">
                     <input type="checkbox" name="desktop_content" checked={desktopcontent} onChange={() => setDesktopcontent(!desktopcontent)} />
                     <span className="slider"></span>
                </label>
                <label>Date</label>
                <input type="date" name="date" onChange={(e) => setDate(e.target.value)} value={date} required/>
                <div className="select_multiple">
                <label>Products</label>
                  <Product />
                </div>
                <div className="select_multiple">
                <label>Categories</label>
                    <Category />
                </div>
                <div className="select_multiple">
                <label>Tags</label>
                    <Tag />
                </div>
                <div className="select_multiple">
                <label>Countries</label>
                    <Cty />
                </div>
                <div className="select_multiple">
                <label>Operators</label>
                    <Op />
                </div>
                <div className="select_multiple">
                <label>Providers</label>
                    <Provider />
                </div>
                <label>Video preview</label>
                <input  name="videopreview" type="file" onChange={(e) => setVideopreview(e.target.files[0])}/>
                <label>Main image</label>
                <input name="mainimg" type="file" onChange={(e) => setMainimg(e.target.files[0])} />
                <label>Secondary image</label>
                <input name="secondaryimg" type="file" onChange={(e) => setSecondaryimg(e.target.files[0])} />
            </div>
         
        
              
            </form>
        </div>
        { redirect === true ? <Navigate to={`/post/${id}`} /> : null }
        </Fragment>
    );   
}

export default Addpost;