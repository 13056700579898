import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Checklogin from "../middleware/Checklogin";
import server from "../Config";

const Updatelang = () => {

 const [colorinstance, setColorinstance] = useState("red"); 
 const [message, setMessage] = useState("");
 const [data, setData] = useState([{}]);   
 const [name, setName] = useState("");
 const [slug, setSlug] = useState("");
 const { id } = useParams();
 const token = sessionStorage.getItem("token");


useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    

        fetch(server+"/api/lang/" + id, requestOptions)
        .then(response => response.json())
        .then(result => {
            setData(result);
            setName(result[0].name);
            setSlug(result[0].slug);
        })
        .catch(error => console.log('error', error));
    
},[id]);


const handleSubmit = (e) => {
    e.preventDefault();

    if(name){
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "name":name,"slug":slug });
    
        const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body:raw,
        redirect: 'follow'
        };
        
        fetch(server+"/api/lang/" + id, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result[0].message){
            setMessage("Your Language was successfully updated");
                setColorinstance("forestGreen");
                setTimeout(()=>{
                    setMessage("");
                }, 5000);

            }
        })
        .catch(error => console.log('error', error));
    } else {
        setColorinstance("red");
        setMessage("Please enter a Name");
        setTimeout(()=>{
            setMessage("");
        }, 3000);
    }
    
}

    return(
        <Fragment>
        <Checklogin />
        <div className="container">
            <form onSubmit={handleSubmit}>
                { data.map((data, index) => (
                    <Fragment key={index}>
            <div className="content_left">
            <div className="input_group">
                <span className="input_group_text">Name</span>
                    <input className="name" onChange={(e) => setName(e.target.value)} name="name" type="text" defaultValue={data.name} required />
            </div>
            <div className="input_group">
                <span className="input_group_text">Slug</span>
                    <input name="slug" onChange={(e) => setSlug(e.target.value)} type="slug" defaultValue={data.slug} required/>
            </div>
            </div>
            <div className="content_right">
                <button className="btn btn_primary" type="submit">
                   Update
                </button>
                <div id="message_post" style={{color:colorinstance}}>{message}</div>
               
            </div>
            </Fragment>
            ))}
              
            </form>
        </div>
        </Fragment>
    );   
}

export default Updatelang;