import React, { Fragment, useEffect, useState } from "react";
import Pagination from '@mui/material/Pagination';
import Delete from "./Delete";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import server from "../Config";
import { clean_lang, change_lang } from '../redux/Appslice';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';


const Taxonomylist = () => {

    const dispatch = useDispatch();
    const { tax } = useParams();
    const [data, setData] = useState([{}]);
    const [del, setDel] = useState("");
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const token = sessionStorage.getItem("token");
    const listupdate = useSelector((state) => state.appState.updatetaxonomy[0]);
    const trash = useSelector((state) => state.appState.delete[0]);

    const submit = (id) => {
    confirmAlert({
      title: 'Confirm to Delete id:'+id,
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { 
            setDel(id);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

var con = 0;

    useEffect(() => {

      if(listupdate === tax ){
        setPage(1);
      }

      con++;

      if(con === 1){
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
      
        fetch(server+"/api/taxonomy?taxonomy="+tax+"&page="+page, requestOptions)
        .then(response => response.json())
        .then(result => {
          const total = result[0].count / 20;
          const round = Math.round(total);
          setCount(round);
          result.message ?
            setData([{
                "id":"",
                "name":"",
                "slug":""
            }])
            :
            setData(result)
        })
        .catch(error => console.log('error', error));
      }
    },[tax, listupdate, trash, page]);

    const handleChange = (e, page) => {
      setPage(page);
    };

    return(
        <Fragment>
        <Delete type="taxonomy" id={del} />
        <table>
        <thead>
            <tr className="head_tr">
                <td>ID</td>
                <td>Name</td>
                <td>Slug</td>
                <td>Action</td>
            </tr>
        </thead>
        <tbody>
           
            { 
            data.map((taxonomy, index)  => (
            <Fragment key={index}>
            <tr>
                <td>{taxonomy.id}</td>
                <td>{taxonomy.name}</td>
                <td>{taxonomy.slug}</td>
                <td>
                    <div className="pencil"><Link to={`/taxonomy/${tax}/${taxonomy.id}`} onClick={()=> {
                      dispatch(clean_lang());
                      dispatch(change_lang("es")); 
                      }}><FontAwesomeIcon  icon={faPencil} /></Link></div>
                    <div className="trash" onClick={() => submit(taxonomy.id)}><FontAwesomeIcon  icon={faTrashCan} /></div>
                    </td>
            </tr>
            </Fragment> 
             ))}
            
        </tbody>
        </table>
        <div className="pagination">
          <Pagination count={count} showFirstButton showLastButton   onChange={handleChange} />
        </div>
        </Fragment>
    );
}

export default Taxonomylist;

