import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import server from "../Config";

const Checklogin = () => {

    const [data, setData] = useState(-1);

    useEffect(() => {

    const token = sessionStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
    };

    fetch(server+"/api/user/validate", requestOptions)
    .then(response => response.json())
    .then(result => {
        setData(result[0].validate);       
    })
    .catch(error => console.log('error', error));
    
},[]);

    return data === 0 ? <Navigate to="/" /> : null ;

}

export default Checklogin;