import React, { useEffect, useState } from "react";
import server from "../../Config";
import Select from 'react-select';
import { change_product, clean_product } from '../../redux/Appslice';
import { useDispatch } from 'react-redux';

const Product = ({defaultValue}) => {

    const [taxonomies, setTaxonomies] = useState("");
    const [select, setSelect] = useState(defaultValue);
    const dispatch = useDispatch();

    var con = 0;

    useEffect(() => {
        setSelect(defaultValue);
        con++;

        if(con === 1){
            const token = sessionStorage.getItem("token");
            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");
        
            const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
        
            fetch(server+"/api/taxonomy?taxonomy=product", requestOptions)
            .then(response => response.json())
            .then(result => {
                const options = [];
                result.map(data => {
                    options.push({ value: data.id, label: data.name });
                });
            
                setTaxonomies(options); 
                
            })
            .catch(error => console.log('error', error));
        }
    },[defaultValue]);

    const handleSelect = (data) => {
        setSelect(data);
        dispatch(clean_product());
    }

    useEffect(()=>{
        dispatch(clean_product());
        dispatch(change_product(select));
    },[select]);

    return(
        <Select
        isMulti
        name="product"
        options={taxonomies}
        className="basic-multi-select"
        classNamePrefix="select"
        value={select}
        onChange={handleSelect}
        placeholder="Select Product"
      />  
    )
}

export default Product;