import React, { useEffect, useState } from "react";
import server from "../../Config";
import Select from 'react-select';
import { change_op, clean_op } from '../../redux/Appslice';
import { useDispatch } from 'react-redux';

const Op = ({defaultValue}) => {

    const [taxonomies, setTaxonomies] = useState([{}]);
    const [select, setSelect] = useState(defaultValue);

    const dispatch = useDispatch();

    var con = 0;

    useEffect(() => {
        setSelect(defaultValue);
        con++;

        if(con === 1){
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
        fetch(server+"/api/taxonomy?taxonomy=op", requestOptions)
        .then(response => response.json())
        .then(result => {
            const options = [];
            result.map(data => {
                options.push({ value: data.id, label: data.name });
            });
        
            setTaxonomies(options); 
        })
        .catch(error => console.log('error', error));
    }

    },[defaultValue]);

    const handleSelect = (data) => {
        setSelect(data);
        dispatch(clean_op());
    }

    useEffect(()=>{
        dispatch(clean_op());
        dispatch(change_op(select));
    },[select]);


    return(
        <Select
        isMulti
        name="op"
        options={taxonomies}
        className="basic-multi-select"
        classNamePrefix="select"
        value={select}
        onChange={handleSelect}
        placeholder="Select Operator"
      />
    )
}

export default Op;