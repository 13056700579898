import React, { Fragment } from "react";
import Updatetaxonomy from "../components/Updatetaxonomy";
import Checklogin from "../middleware/Checklogin";

const Taxonomy = () => {
    return(
        <Fragment>
        <Checklogin />
        <div className="container">
            <div className="taxonomy">
                <Updatetaxonomy />
            </div>
        </div>
        </Fragment>
    );
}

export default Taxonomy;