import React, { Fragment } from "react";

import Checklogin from "../middleware/Checklogin";
import { useParams } from "react-router-dom";
import Updatepost from "../components/Updatepost";
import Addpost from "../components/Addpost";


const User = () => {
    const { id } = useParams();
    return(
    <Fragment>
        <Checklogin />
        { id ? <Updatepost />
             : <Addpost />
        }
   </Fragment>
   );
 
}

export default User;