import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Checklogin from "../middleware/Checklogin";
import server from "../Config";
import Delete from "../components/Delete";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { clean_cty, clean_op, clean_tag, clean_provider, clean_category, clean_lang, change_lang } from '../redux/Appslice';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

const Posts = () => {

    const [data, setData] = useState([{}]);
    const [delpost, setDelpost] = useState(0);

    const dispatch = useDispatch();

    const trash = useSelector((state) => state.appState.delete[0]);

    const submit = (id) => {
        confirmAlert({
          title: 'Confirm to Delete id:'+id,
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => { 
                setDelpost(id);
              }
            },
            {
              label: 'No'
            }
          ]
        });
      }

      var con = 0;

    useEffect(() => {
      con++
      if(con === 1){
        const token = sessionStorage.getItem("token");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
        
        fetch(server+"/api/post", requestOptions)
        .then(response => response.json())
        .then(result => {
          result.message ?
            setData([{
                "id":"",
                "title":"",
                "author":"",
                "date":""
            }])
            :
            setData(result);
        })
      
        .catch(error => console.log('error', error));
      }
    },[trash]);

    return(
        <Fragment>
        <Checklogin />
        <Delete type="post" id={delpost} />
        <div className="container">
        <button className="btn btn_primary" id="new_content" type="button"><Link to="/post"  onClick={()=> { 
          dispatch(clean_cty());
          dispatch(clean_op());
          dispatch(clean_tag());
          dispatch(clean_category());
          dispatch(clean_provider());

          }}>New content</Link></button>
            <table>
            <thead>
                <tr className="head_tr">
                    <td>ID</td>
                    <td>Title</td>
                    <td>Author</td>
                    <td>Date</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
               
                { 
                data.map((post, index)  => (
                <Fragment key={index}>
                <tr>
                    <td>{post.id}</td>
                    <td>{post.title}</td>
                    <td>{post.author}</td>
                    <td>{post.date}</td>
                    <td>
                        <div className="pencil"><Link to={`/post/${post.id}`} onClick={()=> {
                      dispatch(clean_lang());
                      dispatch(change_lang("es")); 
                      }}><FontAwesomeIcon  icon={faPencil} /></Link></div>
                        <div onClick={()=> submit(post.id)} className="trash"><FontAwesomeIcon  icon={faTrashCan} /></div>
                        </td>
                </tr>
                </Fragment> 
                     ))
                }
                
            </tbody>
            </table>
        </div>
        </Fragment>
    );
}

export default Posts;