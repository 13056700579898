import React, { Fragment, useState } from "react";
import Checklogin from "../middleware/Checklogin";
import server from "../Config";

const Createuser = () => {

 const [colorinstance, setColorinstance] = useState("red"); 
 const [message, setMessage] = useState("");
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [role, setRole] = useState("editor");
 const token = sessionStorage.getItem("token");

const handleSubmit = (e) => {
    e.preventDefault();

    if(name){
        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ "name":name,"email":email,"password":password,"role":role });
    
        const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw,
        redirect: 'follow'
        };
  
        fetch(server+"/api/user/", requestOptions)
        .then(response => response.json())
        .then((result) => {
            if(result[0].insertId){
                setMessage("Your User was successfully created");
                setColorinstance("forestGreen");
                setTimeout(()=>{
                    setMessage("");
                }, 5000);
            }else if(result[0].message){
                setMessage(result[0].message);
                setColorinstance("red");
                setTimeout(()=>{
                    setMessage("");
                }, 5000);
            }else{
                setMessage("Your user was not created");
                setColorinstance("red");
                setTimeout(()=>{
                    setMessage("");
                }, 5000);
            }
          
        })
        .catch(error => console.log('error', error));
    } else {
        setColorinstance("red");
        setMessage("Please enter a Name");
        setTimeout(()=>{
            setMessage("");
        }, 3000);
    }
   
}

    return(
        <Fragment>
        <Checklogin />
        <div className="container">
            <form onSubmit={handleSubmit}>
            
            <div className="content_left">
            <div className="input_group">
                <span className="input_group_text">Name</span>
                    <input className="name" onChange={(e) => setName(e.target.value)} name="name" type="text"  required />
            </div>
            <div className="input_group">
                <span className="input_group_text">Email</span>
                    <input name="email" onChange={(e) => setEmail(e.target.value)} type="email" required />
            </div>
            <div className="input_group">
                <span className="input_group_text">Password</span>
                    <input name="password" onChange={(e) => setPassword(e.target.value)} type="password"  required />
            </div>
            <div className="input_group">
                <span className="input_group_text">Role</span>
                <select
                id="role"
                name="role"
                onChange={e => setRole(e.target.value)}>
                    <option value="">Choose here</option>
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
            </select>
            </div>
            </div>
            <div className="content_right">
                <button className="btn btn_primary" type="submit">Save</button>
                <div id="message_post" style={{color:colorinstance}}>{message}</div>
               
            </div>          
            </form>
        </div>
        </Fragment>
    );   
}

export default Createuser;